


















































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateFormat from '@/utils/dateFormat';
import { Shipment } from '@/models/shipment';
import { ErrorManager } from '@/models/error';
import ShipmentInvoice from './ShipmentInvoice.vue';
import { Account } from '@/models/account';

export default Vue.extend({
  components: { ShipmentInvoice },
  name: 'Past-Shipment',
  data() {
    return {
      search: '',
      errorMessage: '',
      loading: false,
      open: false
    };
  },

  computed: {
    ...mapState('shipment', ['shipment']),
    ...mapGetters({
      getCurrentShipments: 'shipment/getCurrentShipments'
    }),

    userInfo() {
      return JSON.parse(localStorage.getItem('user')!);
    },

    currentShipment(): Shipment[] {
      const userId = this.userInfo == null ? null : this.userInfo.pk;

      return this.shipment.filter(
        (model: Shipment) =>
          model.status != 'P' &&
          model.user &&
          (model.user as Account)!.pk == userId
      );
    },

    totalPackagePrice(): string {
      let totalPackagePrice = 0;
      this.currentShipment.forEach((shipment: Shipment) => {
        if (shipment.status == 'RP') {
          return (totalPackagePrice += shipment.package_pickup_cost!);
        }
      });
      return totalPackagePrice.toFixed(2);
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      fetchShipments: 'shipment/fetchShipments'
    }),
    dateFormat,

    filterShipmentObject(queryText: string) {
      const filter = this.currentShipment.filter((element: Shipment) => {
        return (
          element
            .description!.toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1
        );
      });
      return filter;
    },

    openFileDialog(shipment: Shipment) {
      (this as any).$refs.shipmentinvoicedialog.open(shipment);
    },
    genShipment() {
      this.loading = true;
      this.fetchShipments()
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  mounted() {
    this.genShipment();
  }
});
